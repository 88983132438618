.birthday-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p{
    font-size: 1.5rem;
  }
  
  .date-inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .date-inputs input {
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 80px;
    text-align: center;
  }
  
  .birthday-form button {
    padding: 10px 20px;
    background-color: #0d0143;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .birthday-form button:hover {
    background-color: #0b0020;
  }
  